import { RouteObject } from 'react-router';
import { AuthRequiredRoute } from '@/route/route-entries.ts';
import ProtectedRoute from '@/route/protected-route.tsx';
import Layout from '@/components/layout';
import { Navigate } from 'react-router-dom';
import { AuthRouterConfig } from '@/pages/auth/router.config.tsx';
import React from 'react';
import { AppRoutes } from '@/route/routes.ts';
import { NotFound } from '@/pages/not-found/not-found.tsx';

const ErrorPage = React.lazy(() =>
    import('../pages/error-page/error-page').then((module) => ({ default: module.ErrorPage })),
);

export const routerConfig: RouteObject[] = [
    {
        path: '',
        element: <Navigate to="/auth/login" />,
    },
    ...AuthRequiredRoute.map((route) => ({
        path: route.route,
        element: (
            <ProtectedRoute>
                <Layout />
            </ProtectedRoute>
        ),
        children: [
            {
                path: '',
                element: <route.component />,
            },
        ],
        errorElement: <ErrorPage />,
    })),
    ...AuthRouterConfig,
    {
        path: AppRoutes.NOT_FOUND,
        element: <NotFound />,
    },
    {
        path: '*',
        element: <Navigate to={`/${AppRoutes.NOT_FOUND}`} />,
    },
];
