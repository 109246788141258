import { clsx } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from './navbar';
import Sidebar from './sidebar';
import { useCurrentUser } from '@/lib/hooks/current-user/useCurrentUser';
import { LoadingScreen } from '../ui/loading-screen/loading-screen';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { setRoleStatus } from '@/redux/slices/auth';

const Layout = () => {
    const { isSuperAdmin, isLoading, isSuccess } = useCurrentUser();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { roleIndentified } = useAppSelector((state) => state.user);

    const [collaps, setCollaps] = useState(false);

    const toggle = (
        _e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLLabelElement>,
        mode?: boolean,
    ) => {
        if (mode) setCollaps(false);
        else setCollaps((prev) => !prev);
    };

    useEffect(() => {
        if (!roleIndentified && isSuccess) {
            if (isSuperAdmin) {
                navigate('/house-managers');
            }
            dispatch(setRoleStatus(true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roleIndentified, isSuccess]);

    if (isLoading) return <LoadingScreen />;

    return (
        <div className="relative min-h-screen md:flex" data-dev-hint="container">
            <input type="checkbox" id="menu-open" className="hidden" />

            <aside
                id="sidebar"
                className={clsx(
                    'md:w-64 w-3/4 max-w-xs bg-background space-y-6 px-0 z-20 absolute inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out  md:flex md:flex-col md:justify-between overflow-y-auto flex-shrink-0',
                    collaps ? 'md:w-16' : 'md:w-72',
                )}
                data-dev-hint="sidebar px-0 for frameless; px-2 for visually inset the navigation">
                <Sidebar collaps={collaps} />
            </aside>

            <main id="content" className="flex-1 bg-accent min-h-screen h-max">
                <Navbar toggle={toggle} collaps={collaps} />
                <div className="max-w-[1500px] mx-auto px-8 py-6 h-[calc(100vh-64px)] overflow-auto">
                    <Outlet />
                </div>
            </main>
        </div>
    );
};

export default Layout;
