import { Button } from '@/components/ui/button/button.tsx';
import { Label } from './ui/label';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PasswordInput from './ui/password-input';
import { useChangePasswordMutation } from '@/redux/queries/auth';
import { useToast } from './ui/toast/use-toast.ts';
import { useAppDispatch } from '@/lib/hooks';
import { api } from '@/redux/baseConfig';
import { userLogout } from '@/redux/slices/auth';
import { t } from 'i18next';
import { Modal } from '@/components/ui/modal/modal.tsx';
import H1 from '@/components/typo/H1.tsx';
import Paragraph from '@/components/typo/Paragraph.tsx';
import { useTranslation } from 'react-i18next';
import { isErrorWithMessage } from '@/lib/utils/errorUtils.ts';

interface ChangePasswordProps {
    open: boolean;
    onClose: () => void;
}

interface IChangePasswordForm {
    oldPassword: string;
    password: string;
    passwordConfirm: string;
}

const changePasswordSchema = yup.object().shape({
    oldPassword: yup.string().required(t('users.currentPasswordMessage')),
    password: yup
        .string()
        .min(8, t('users.passwordLengthMessage'))
        .required(t('users.pleaseEnterNeuPassword')),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password'), undefined], t('users.passwordMatchMessage'))
        .required(t('users.pleaseConfirmPassword')),
});

const ChangePassword = ({ onClose, open }: ChangePasswordProps) => {
    const { toast } = useToast();
    const [t] = useTranslation();
    const dispatch = useAppDispatch();

    const [updatePassword, { isLoading }] = useChangePasswordMutation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(changePasswordSchema),
    });

    const submit = async (data: IChangePasswordForm) => {
        const payload = {
            oldPassword: data.oldPassword,
            newPassword: data.password,
        };

        const res = await updatePassword(payload);

        if (res.data === null) {
            toast({
                variant: 'default',
                title: t('users.passwordUpdated'),
                description: t('users.passwordUpdatedDescription'),
            });
            dispatch(userLogout());
            dispatch(api.util.resetApiState());
        } else if (isErrorWithMessage(res)) {
            toast({
                variant: 'error',
                title: t('users.error.title'),
                description: t('users.error.description'),
            });
        } else {
            toast({
                variant: 'error',
                title: t('users.error.title'),
                description: t('users.error.description'),
            });
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <div className="sm:max-w-[425px] flex flex-col gap-2">
                <div className={'flex flex-col gap-2'}>
                    <H1>{t('users.changePassword')}</H1>
                    <Paragraph>{t('users.changePasswordDescription')}</Paragraph>
                </div>
                <form className="flex flex-col gap-4" onSubmit={handleSubmit(submit)}>
                    <div className="items-center gap-2">
                        <Label htmlFor="password" className="text-right">
                            {t('users.oldPassword')}
                        </Label>
                        <PasswordInput
                            {...register('oldPassword')}
                            placeholder="******"
                            autoComplete="off"
                            className="col-span-3"
                            error={errors.oldPassword?.message}
                        />
                    </div>
                    <div className="items-center gap-4 ">
                        <Label htmlFor="password" className="text-right">
                            {t('users.password')}
                        </Label>
                        <PasswordInput
                            {...register('password')}
                            placeholder="******"
                            autoComplete="off"
                            className="col-span-3"
                            error={errors.password?.message}
                        />
                    </div>
                    <div className="items-center gap-4">
                        <Label htmlFor="confirmPassword" className="text-right">
                            {t('users.passwordConfirmation')}
                        </Label>
                        <PasswordInput
                            {...register('passwordConfirm')}
                            placeholder="******"
                            autoComplete="off"
                            className="col-span-3"
                            error={errors.passwordConfirm?.message}
                        />
                    </div>
                    <div className={'flex justify-end'}>
                        <Button type="submit" disabled={isLoading} isLoading={isLoading}>
                            {t('users.updatePassword')}
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ChangePassword;
