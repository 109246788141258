import { clsx } from '@/lib/utils';
import * as React from 'react';
import { FaAsterisk } from 'react-icons/fa';
import { Label } from './ui/label';

export interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: string;
    id?: string;
    labelClassName?: string;
    isRequired?: boolean;
    inputClassName?: string;
}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
    (
        {
            className,
            inputClassName,
            error,
            label,
            id,
            labelClassName,
            isRequired = false,
            ...props
        },
        ref,
    ) => {
        return (
            <div className={clsx('flex flex-col gap-1.5 w-full', className)}>
                {label && (
                    <div className="flex flex-row gap-1 items-center">
                        <Label
                            htmlFor={id ?? ''}
                            className={clsx('text-sm font-medium', labelClassName)}>
                            {label}
                        </Label>
                        {isRequired && <FaAsterisk size={6} className="text-red-500 mb-1" />}
                    </div>
                )}

                <input
                    id={id}
                    className={clsx(
                        'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-ring focus-visible:border-primary2 disabled:cursor-not-allowed disabled:opacity-50',
                        inputClassName,
                        error && 'border-red-500 focus-visible:border-red-500',
                    )}
                    ref={ref}
                    {...props}
                />
                {error && <span className="text-xs text-red-500">{error}</span>}
            </div>
        );
    },
);

FormInput.displayName = 'FormInput';

export { FormInput };
