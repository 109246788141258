import { api } from '../baseConfig';

export interface IApiVersion {
    'api-version': string;
    version: string;
}

export const apiVersion = api.injectEndpoints({
    endpoints: (builder) => ({
        getApiVersion: builder.query<IApiVersion, void>({
            query: () => ({
                url: '/immo/versionInfo',
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetApiVersionQuery } = apiVersion;
