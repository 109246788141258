import * as Sentry from '@sentry/react';
import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

if (!import.meta.env.DEV) {
    console.log('sentry init');
    Sentry.init({
        dsn: 'https://817fc1427b78e536082f375db3b40df6@o4507726364803072.ingest.de.sentry.io/4507927804641360',
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: ['localhost', /^https:\/\/ip360-api.novus-software\.eu\/api/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
