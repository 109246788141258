import { clsx } from '@/lib/utils';

type WrapperProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

const Wrapper = ({ children, className, ...props }: WrapperProps) => {
    return (
        <main className={clsx(['w-full h-full', className])} {...props}>
            {children}
        </main>
    );
};

export default Wrapper;
