import React from 'react';
import { ErrorPage } from '@/pages/error-page/error-page.tsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/**
 * Not found page
 */
export const NotFound: React.FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();

    return (
        <ErrorPage
            errorTitle={t('error.notFound.title')}
            errorDescription={t('error.notFound.description')}
            buttonText={t('error.notFound.action')}
            onClick={() => navigate(`/`)}
            imageSource={'not-found-error.svg'}
        />
    );
};
