import { useGetCurrentUserInfoQuery } from '@/redux/queries/users';
import { PropsWithChildren } from 'react';
import isNil from 'lodash.isnil';

interface PermissionControlProps extends PropsWithChildren {
    /** The access array */
    access?: string[];
}

/**
 * Wrapper that shows/hides the passed children based on the permissions of the current user
 * If no access array is provided the wrapper will just show the children
 */
const PermissionControl = ({ children, access }: PermissionControlProps) => {
    const { data: currentUserInfo, isLoading } = useGetCurrentUserInfoQuery();
    const userRole = currentUserInfo?.userRole;

    if (!currentUserInfo && !isLoading) return children;

    // if no access array was provided the permissions can be ignored
    if (isNil(access)) return children;

    //Check permissions
    if (access?.includes(String(userRole))) {
        return children;
    }

    return null;
};

export default PermissionControl;
