import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { AuthFormCard } from '@/pages/auth/components/auth-form-card/auth-form-card.tsx';
import H3 from '@/components/typo/H3.tsx';
import { useTranslation } from 'react-i18next';
import { Label } from '@/components/ui/label.tsx';
import { FormInput } from '@/components/FormInputs.tsx';
import { useChangePasswordMutation } from '@/redux/queries/auth.ts';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '@/components/ui/button/button.tsx';
import Paragraph from '@/components/typo/Paragraph.tsx';
import { AppLink } from '@/components/ui/app-link/app-link.tsx';
import { AuthRoutes } from '@/pages/auth/router.config.tsx';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { toast } from '@/components/ui/toast/use-toast.ts';
import isNil from 'lodash.isnil';
import { useAppDispatch } from '@/lib/hooks';
import { api } from '@/redux/baseConfig';
import { userLogout } from '@/redux/slices/auth';

const createPasswordChangeScheme = (t: TFunction<'translation', undefined>) =>
    yup.object().shape({
        newPassword: yup
            .string()
            .required(t('validation.required'))
            .min(8, t('validation.password.minLength')),
        newPasswordRepeat: yup
            .string()
            .required(t('validation.required'))
            .oneOf([yup.ref('newPassword')], t('validation.password.mustMatch')),
    });

export const PasswordChange: React.FC = () => {
    const [t] = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const resetScheme = useMemo(() => createPasswordChangeScheme(t), [t]);
    const [changePasswordRequest, { isLoading }] = useChangePasswordMutation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: yupResolver(resetScheme) });

    useEffect(() => {
        if (isNil(searchParams.get('token'))) navigate(`/${AuthRoutes.AUTH}/${AuthRoutes.LOGIN}`);
    }, [searchParams, navigate]);

    useLayoutEffect(() => {
        dispatch(userLogout());
        dispatch(api.util.resetApiState());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = useCallback(
        async ({ newPassword }: { newPassword: string; newPasswordRepeat: string }) => {
            try {
                localStorage.removeItem('authToken');
                await changePasswordRequest({
                    newPassword: newPassword,
                    resetToken: searchParams.get('token') ?? '',
                }).unwrap();
                toast({
                    variant: 'default',
                    title: t('general.success'),
                    description: t('login.changePassword.successful'),
                });
                navigate(`/${AuthRoutes.AUTH}/${AuthRoutes.LOGIN}`);
            } catch (err) {
                toast({
                    variant: 'error',
                    title: t('error.internalServerError'),
                    description: t('login.reset.unableToReset'),
                });
                throw err;
            }
        },
        [searchParams, changePasswordRequest, t, navigate],
    );

    return (
        <AuthFormCard onSubmit={handleSubmit(onSubmit)}>
            <H3 className={'w-full animate-in animate-bounce md:visible'}>
                {t('login.changePassword')}
            </H3>
            <div className={'flex flex-col gap-1.5 w-full items-start justify-center'}>
                <Label htmlFor="newPassword">{t('login.changePassword.new')}</Label>
                <FormInput
                    {...register('newPassword')}
                    type="password"
                    id="newPassword"
                    placeholder={t('login.changePassword.new')}
                    autoComplete={'new-password'}
                    error={errors.newPassword?.message}
                    disabled={isLoading}
                />
            </div>
            <div className={'flex flex-grow flex-col gap-1.5 w-full items-start justify-center'}>
                <Label htmlFor="newPasswordRepeat">{t('login.changePassword.newRepeat')}</Label>
                <FormInput
                    {...register('newPasswordRepeat')}
                    type="password"
                    id="newPasswordRepeat"
                    placeholder={t('login.changePassword.newRepeat')}
                    error={errors.newPasswordRepeat?.message}
                    disabled={isLoading}
                />
            </div>
            <Button
                className="w-full max-w-sm text-white"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}>
                {t('general.submit')}
            </Button>
            <Paragraph className="text-[13px] text-muted-foreground flex gap-2 flex-wrap">
                <AppLink
                    text={t('login.goBack')}
                    onClick={() => navigate(`/${AuthRoutes.AUTH}/${AuthRoutes.LOGIN}`)}
                />
            </Paragraph>
        </AuthFormCard>
    );
};
