export namespace UserUtils {
    /**
     * Gets initials from a user object
     * @param
     * @param toUppercase
     */
    export const getUserInitials = (
        { username, firstName, lastName }: IUser | ICurrentUser,
        toUppercase?: boolean,
    ) => {
        let initials: string;
        if (firstName.length === 0 && lastName.length === 0) initials = username.substring(0, 2);
        else initials = `${firstName?.[0] ?? ''}${lastName?.[0] ?? ''}`.trim();
        if (toUppercase) initials = initials.toUpperCase();
        return initials;
    };
}
