import React from 'react';

export interface AppLinkProps {
    text: string;
    onClick?: () => void;
}

export const AppLink: React.FC<AppLinkProps> = ({ text, onClick }) => {
    return (
        <button className="hover:underline text-primary2" type={'button'} onClick={onClick}>
            {text}
        </button>
    );
};
