import styles from './error-page.module.css';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button/button.tsx';
import { AppIcon, appIcons } from '@/components/ui/app-icon/app-icon.tsx';
import React from 'react';
import { isFunction } from 'radash';

/**
 * Properties used by {@link ErrorPage}
 */
interface ErrorPageProps {
    /** The error title */
    errorTitle?: string;
    /** The error description */
    errorDescription?: string;
    /** The on click override */
    onClick?: () => void;
    /** The button text */
    buttonText?: string;
    /** The image source (file name of the image located in /public/assets/img/ */
    imageSource?: string;
}

/**
 * General error page for displaying an error to the user
 */
export const ErrorPage: React.FC<ErrorPageProps> = ({
    errorTitle,
    errorDescription,
    onClick,
    buttonText,
    imageSource = 'generalError.svg',
}) => {
    const [t] = useTranslation();
    return (
        <div className={styles['error-container']}>
            <img src={`/assets/img/${imageSource}`} alt="" />
            <div>
                <h1>{errorTitle ?? t(`error.badRequest`)}</h1>
                <div>{errorDescription ?? t(`error.retry`)}</div>
            </div>

            <Button onClick={isFunction(onClick) ? onClick : () => window.location.reload()}>
                <AppIcon icon={appIcons.refresh} />
                {buttonText ?? t('general.reload')}
            </Button>
        </div>
    );
};
