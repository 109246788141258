import { HTMLHeadingElementProps } from '@/types/globals';

const H1 = ({ children, className, ...props }: HTMLHeadingElementProps) => {
    return (
        <h1 className={`scroll-m-20 font-extrabold tracking-tight text-xl ${className}`} {...props}>
            {children}
        </h1>
    );
};

export default H1;
