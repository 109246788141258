import { VITE_BASE_URL } from '@/lib/constants';
import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authUser, userLogout } from './slices/auth';
import { Mutex } from 'async-mutex';
import { RootState } from './store';
import { captureException } from '@sentry/react';

// Mutex to prevent multiple refresh calls
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: VITE_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const userRecord = getState() as RootState;
        const accessToken = userRecord.user?.authenticatedUser?.accessToken;
        if (accessToken) {
            headers.set('Authorization', `Bearer ${accessToken}`);
        }
        return headers;
    },
});

const baseQueryWithReAuth: BaseQueryFn = async (args, api, extraOptions) => {
    // Wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    let response = await baseQuery(args, api, extraOptions);
    if (response.error?.status === 401) {
        // Checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const userRecord = api.getState() as RootState;
                const refreshToken = userRecord.user?.authenticatedUser?.refreshToken;

                const refreshResult = (await baseQuery(
                    {
                        url: '/auth/refresh',
                        method: 'POST',
                        body: { refreshToken },
                    },
                    api,
                    extraOptions,
                )) as { data: { accessToken: string; refreshToken: string; type: string } };

                if (refreshResult.data) {
                    api.dispatch(
                        authUser({
                            accessToken: refreshResult.data.accessToken,
                            refreshToken: refreshResult.data.refreshToken,
                            type: refreshResult.data.type,
                        }),
                    );

                    // Retry the original query with new access token
                    response = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(userLogout());
                }
            } finally {
                // Release the mutex
                release();
            }
        } else {
            // If the mutex is locked, wait for it to be unlocked and then retry the query
            await mutex.waitForUnlock();
            response = await baseQuery(args, api, extraOptions);
        }
    } else if (response.error) {
        captureException(response.error);
    }
    return response;
};

export const api = createApi({
    baseQuery: baseQueryWithReAuth,
    reducerPath: 'apiPath',
    tagTypes: [
        'buildings',
        'meters',
        'readings',
        'contractors',
        'users',
        'houseManagement',
        'communityOwners',
        'user',
    ],
    endpoints: () => ({}),
});
