import React from 'react';
import styles from './app-icon.module.css';
import { shake } from 'radash';
import { clsx } from '@/lib/utils.ts';

interface AppIconProps {
    icon: string;
    size?: number;
    color?: string;
    className?: string;
    onClick?: React.MouseEventHandler;
}

export const AppIcon: React.FC<AppIconProps> = ({ icon, size, color, className, onClick }) => {
    return (
        <div
            className={clsx(styles.appIcon, className)}
            style={shake({ fontSize: size, color })}
            onClick={onClick}>
            {icon}
        </div>
    );
};

export const appIcons = {
    add: '',
    addBuilding: '',
    addUser: '',
    accountDelete: '',
    accountSwitch: '',
    back: '',
    camera: '',
    calendar: '',
    calendarAlert: '',
    close: '',
    counter: '',
    compass: '',
    community: '',
    delete: '',
    deleteProfile: '',
    deregister: '',
    edit: '',
    energyProvider: '',
    export: '',
    filter: '',
    filterActive: '',
    fire: '',
    home: '',
    houseManager: '',
    image: '',
    import: '',
    info: '',
    lamp: '',
    location: '',
    lampOff: '',
    lightning: '',
    list: '',
    lock: '',
    logout: '',
    meter: '',
    options: '',
    passwordReset: '',
    privacy: '',
    refresh: '',
    search: '',
    scanIcon: '',
    settings: '',
    starFilled: '',
    starOutlined: '',
    support: '',
    swap: '',
    user: '',
    users: '',
    water: '',
    warn: '',
};
