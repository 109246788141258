import { clsx } from '@/lib/utils';
import { Link } from 'react-router-dom';
import { sideBarEntries } from './side-bar.config.tsx';
import SideBarItem from './side-bar-item';
import { Separator } from '@/components/ui/separator.tsx';
import { useGetApiVersionQuery } from '@/redux/queries/common.ts';
import { useTranslation } from 'react-i18next';
import PermissionControl from '@/components/PermissionControl.tsx';
import { UserRole } from '@/types/access.types.ts';

interface ISidebar {
    collaps: boolean;
}

const Sidebar = ({ collaps }: ISidebar) => {
    const [t] = useTranslation();
    const { data: apiVersion } = useGetApiVersionQuery();

    return (
        <div className="flex flex-col h-full text-foreground px-3">
            <div
                className={clsx('flex flex-col space-y-6 px-1')}
                data-dev-hint="optional div for having an extra footer navigation">
                <div className="h-16 relative flex justify-between items-center">
                    <Link to="/" className="flex" title="immo+ 360 Web App">
                        {collaps ? (
                            <img src="/assets/img/app-logo.svg" alt="" />
                        ) : (
                            <img
                                src="/assets/img/app-logo-tagline.svg"
                                alt=""
                                className={clsx('w-40')}
                            />
                        )}
                    </Link>

                    <label
                        htmlFor="menu-open"
                        id="mobile-menu-button"
                        className="focus:outline-none md:hidden rounded-md">
                        <svg
                            id="menu-close-icon"
                            className="h-6 w-6 transition duration-200 ease-in-out"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </label>
                </div>
            </div>

            <nav className="flex flex-col flex-1 overflow-auto gap-2 mt-6">
                <div className={'flex flex-col gap-2 flex-grow'}>
                    {sideBarEntries.map((group, index) => (
                        <div key={`group-${index}`} className={'flex flex-col gap-2'}>
                            {index !== 0 && (
                                <PermissionControl access={[UserRole.Admin, UserRole.SuperAdmin]}>
                                    <Separator className="w-full" />
                                </PermissionControl>
                            )}
                            {group.entries.map((entry) => (
                                <PermissionControl key={entry.title} access={entry.access}>
                                    <SideBarItem menu={entry} isCollapsed={collaps} />
                                </PermissionControl>
                            ))}
                        </div>
                    ))}
                </div>
            </nav>

            <div
                data-dev-hint="second-main-navigation or footer navigation"
                className="text-foreground flex flex-col justify-center my-4">
                {!collaps && (
                    <div className={'flex flex-col gap-2'}>
                        <Separator className={'w-full'} />
                        <div className="text-xs text-slate-500 px-2">
                            {t('general.serverVersion', { version: apiVersion?.version ?? '-' })}
                        </div>

                        <div className="text-xs text-slate-500 px-2">
                            {t('general.webAppVersion', {
                                version: import.meta.env.VITE_APP_VERSION ?? '-',
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Sidebar;
