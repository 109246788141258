export interface IErrorResponse {
    data: {
        message: string;
    };
}

export const isErrorWithMessage = (error: any): error is IErrorResponse => {
    return (
        typeof error === 'object' && error !== null && 'data' in error && 'message' in error.data
    );
};
