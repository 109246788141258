import React, { useCallback, useMemo } from 'react';
import H3 from '@/components/typo/H3.tsx';
import { useTranslation } from 'react-i18next';
import { AuthFormCard } from '@/pages/auth/components/auth-form-card/auth-form-card.tsx';
import { Button } from '@/components/ui/button/button.tsx';
import Paragraph from '@/components/typo/Paragraph.tsx';
import { AppLink } from '@/components/ui/app-link/app-link.tsx';
import { AuthRoutes } from '@/pages/auth/router.config.tsx';
import { useNavigate } from 'react-router-dom';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label } from '@/components/ui/label.tsx';
import { FormInput } from '@/components/FormInputs.tsx';
import { useResetPasswordMutation } from '@/redux/queries/auth.ts';
import { get } from 'radash';
import { toast } from '@/components/ui/toast/use-toast.ts';

const createResetScheme = (t: TFunction<'translation', undefined>) =>
    yup.object().shape({
        email: yup.string().email(t('validation.email.invalid')).required(t('validation.required')),
    });

export const PasswordReset: React.FC = () => {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const resetScheme = useMemo(() => createResetScheme(t), [t]);
    const [resetPasswordRequest, { isLoading }] = useResetPasswordMutation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{ email: string }>({ resolver: yupResolver(resetScheme) });

    const onSubmit = useCallback(
        async ({ email }: { email: string }) => {
            try {
                await resetPasswordRequest({ email }).unwrap();
                toast({
                    variant: 'default',
                    title: t('general.success'),
                    description: t('login.reset.successful', { email }),
                });
                navigate(`/${AuthRoutes.AUTH}/${AuthRoutes.LOGIN}`);
            } catch (err) {
                if (get(err, 'status') === 400)
                    toast({
                        variant: 'error',
                        title: t('error.badRequest'),
                        description: t('login.reset.userUnknown'),
                    });
                else {
                    toast({
                        variant: 'error',
                        title: t('error.internalServerError'),
                        description: t('login.reset.unableToReset'),
                    });
                }
            }
        },
        [resetPasswordRequest, t, navigate],
    );

    return (
        <AuthFormCard onSubmit={handleSubmit(onSubmit)}>
            <div className={'w-full'}>
                <H3 className="animate-in animate-bounce md:visible">{t('login.passwordReset')}</H3>
            </div>

            <div className={'flex-grow flex flex-col gap-1.5 w-full items-start justify-center'}>
                <Label htmlFor="email">{t('login.reset.email')}</Label>
                <FormInput
                    {...register('email')}
                    type="text"
                    id="email"
                    placeholder={t('login.reset.email.placeholder')}
                    autoComplete={'username'}
                    error={errors.email?.message}
                    disabled={isLoading}
                />
            </div>

            <Button
                className="w-full max-w-sm text-white"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}>
                {t('login.passwordReset.action')}
            </Button>

            <Paragraph className="text-[13px] text-muted-foreground flex gap-2 flex-wrap">
                <AppLink
                    text={t('login.goBack')}
                    onClick={() => navigate(`/${AuthRoutes.AUTH}/${AuthRoutes.LOGIN}`)}
                />
            </Paragraph>
        </AuthFormCard>
    );
};
