import { clsx } from '@/lib/utils';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

interface ISidebar {
    menu: IMenu;
    isCollapsed: boolean;
}

interface IMenu {
    icon: JSX.Element;
    title: string;
    badge?: string;
    link: string;
}

const SideBarItem = ({ isCollapsed, menu }: ISidebar) => {
    const { icon, title, link } = menu;
    const [t] = useTranslation();

    const ItemContent = (
        <div>
            {/* ↑ this div is used as an anchor */}
            <NavLink
                to={link}
                className={({ isActive }) =>
                    isActive
                        ? clsx(
                              'bg-primary2/10  dark:text-white py-[9px] rounded-lg flex justify-between items-center gap-2 text-primary2',
                              !isCollapsed && 'px-2',
                          )
                        : 'px-2 py-[9px] rounded-lg flex justify-between items-center gap-2 hover:text-primary2 transition-colors'
                }>
                <div
                    className={clsx(
                        'flex items-center justify gap-2',
                        isCollapsed && 'm-auto',
                        'h-5',
                    )}>
                    <span className="text-forground">{icon}</span>
                    <span className={clsx('text-sm', isCollapsed && 'hidden')}>
                        {t(`${title}.title`)}
                    </span>
                </div>
            </NavLink>
        </div>
    );

    if (isCollapsed)
        return (
            <Tooltip title={t(`${title}.title`)} placement={'right'}>
                {ItemContent}
            </Tooltip>
        );

    return ItemContent;
};

export default SideBarItem;
