import { HTMLParagraphElementProps } from '@/types/globals';
import { clsx } from 'clsx';

const Paragraph = ({ children, className, ...props }: HTMLParagraphElementProps) => {
    return (
        <p
            className={clsx(
                `leading-7`,
                className,
                '[&:not(:first-child)]:mt-0',
                'text-sm',
                'text-foreground',
            )}
            {...props}>
            {children}
        </p>
    );
};

export default Paragraph;
