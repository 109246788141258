import { useMemo } from 'react';
import { useGetCurrentUserInfoQuery } from '@/redux/queries/users';
import { UserRole } from '@/types/access.types';

export const useCurrentUser = () => {
    const {
        data: currentUserInfo,
        isLoading: isCurrentUserInfoLoading,
        isSuccess: isCurrentUserInfoSuccess,
    } = useGetCurrentUserInfoQuery();

    const isAdmin = useMemo(
        () => currentUserInfo?.userRole === UserRole.Admin,
        [currentUserInfo?.userRole],
    );

    const isSuperAdmin = useMemo(
        () => currentUserInfo?.userRole === UserRole.SuperAdmin,
        [currentUserInfo?.userRole],
    );

    return {
        isAdmin,
        isSuperAdmin,
        isLoading: isCurrentUserInfoLoading,
        isSuccess: isCurrentUserInfoSuccess,
        currentUserData: currentUserInfo,
    };
};
