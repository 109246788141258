import React from 'react';
import Wrapper from '@/components/wrapper';
import styles from './auth.module.css';
import { Outlet } from 'react-router-dom';
import { clsx } from '@/lib/utils.ts';

const Auth: React.FC = () => {
    return (
        <Wrapper className={clsx('flex', 'w-full')}>
            <div
                className={clsx(
                    'items-center justify-center',
                    styles.loginLogoWrapper,
                    styles.loginColumn,
                    'flex',
                )}>
                <div className="flex items-center gap-20 flex-col max-w-sm">
                    <img src="/assets/img/app-logo-vertical.svg" alt="Immo+ 360" />
                </div>
            </div>
            <div
                className={clsx(
                    'lg:px-12',
                    'md:px-6',
                    'flex',
                    'justify-center',
                    'items-center',
                    'md:bg-secondary',
                    'sm:bg-white',
                    'gap-6',
                    styles.loginColumn,
                )}>
                <div className={clsx('items-center gap-20 flex-col', styles.loginLogoVertical)}>
                    <img
                        src="/assets/img/app-logo-tagline.svg"
                        alt="Immo+ 360"
                        data-testid={'immoplus-logo'}
                        className={'w-full'}
                    />
                </div>
                <Outlet />
            </div>
        </Wrapper>
    );
};

export default Auth;
