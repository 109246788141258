import { useCallback, useEffect, useState } from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from '@/redux/store';
import { shake } from 'radash';

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// sorting tables
export type SortOrder = 'asc' | 'desc' | undefined;
export type SortState = Record<string, SortOrder>;

export const useSorting = (initialSortState?: SortState) => {
    const [sortState, setSortState] = useState<SortState>(initialSortState || {});

    const toggleSort = useCallback((key: string) => {
        setSortState((prevState) => {
            const currentSort = prevState[key];
            let newSort: SortOrder;
            if (currentSort === 'asc') {
                newSort = 'desc';
            } else if (currentSort === 'desc') {
                newSort = undefined;
            } else {
                newSort = 'asc';
            }

            return shake({ [key]: newSort });
        });
    }, []);

    const getSortParams = (): string[] => {
        return Object.entries(sortState)
            .filter(([, value]) => value)
            .map(([key, value]) => `${key},${value}`);
    };

    return { sortState, toggleSort, getSortParams };
};

//Responsiveness for modal
export const useMediaQuery = (query: string) => {
    const [value, setValue] = useState(false);

    useEffect(() => {
        function onChange(event: MediaQueryListEvent) {
            setValue(event.matches);
        }

        const result = matchMedia(query);
        result.addEventListener('change', onChange);
        setValue(result.matches);

        return () => result.removeEventListener('change', onChange);
    }, [query]);

    return value;
};

//check if capsLock is on/off

const EVENT_KEY_DOWN = 'keydown';
const EVENT_KEY_UP = 'keyup';
export const useCapsLock = () => {
    const [isCapsLockActive, setIsCapsLockActive] = useState<boolean>(false);

    const handleKeyEvent = useCallback((event: KeyboardEvent) => {
        setIsCapsLockActive(event.getModifierState && event.getModifierState('CapsLock'));
    }, []);

    useEffect(() => {
        document.addEventListener(EVENT_KEY_DOWN, handleKeyEvent);
        document.addEventListener(EVENT_KEY_UP, handleKeyEvent);

        if (document.activeElement instanceof HTMLElement) {
            const event = new KeyboardEvent('keydown');
            handleKeyEvent(event);
        }

        return () => {
            document.removeEventListener(EVENT_KEY_DOWN, handleKeyEvent);
            document.removeEventListener(EVENT_KEY_UP, handleKeyEvent);
        };
    }, [handleKeyEvent]);

    return isCapsLockActive;
};
