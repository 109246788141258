import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { clsx } from '@/lib/utils';

const badgeVariants = cva(
    'inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
    {
        variants: {
            variant: {
                default:
                    'border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80',
                secondary:
                    'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
                destructive:
                    'border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive/80',
                outline: 'text-foreground',
                slate: 'bg-slate-500 text-white ring-0 text-xs',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    },
);

export interface BadgeProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof badgeVariants> {
    active?: boolean;
}

function Badge({ className, variant, active, ...props }: BadgeProps) {
    const activeClass = active ? 'bg-green-100 text-green-500' : '';

    return <div className={clsx(badgeVariants({ variant }), activeClass, className)} {...props} />;
}

export { Badge, badgeVariants };
