import React, { PropsWithChildren } from 'react';
import { clsx } from '@/lib/utils.ts';
import styles from './auth-form-card.module.css';

export interface AuthFormCardProps extends PropsWithChildren {
    onSubmit?: () => void;
}

export const AuthFormCard: React.FC<AuthFormCardProps> = ({ children, onSubmit }) => {
    return (
        <form
            className={clsx(
                'flex',
                'flex-col',
                'items-center',
                'gap-6',
                'bg-white',
                'rounded-2xl',
                'p-12',
                styles.loginContainer,
            )}
            onSubmit={onSubmit}>
            {children}
        </form>
    );
};
