import { clsx } from '@/lib/utils';
import * as React from 'react';
import { GoEye, GoEyeClosed } from 'react-icons/go';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useCapsLock } from '@/lib/hooks';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    customPrefix?: any;
    error?: string;
}

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, error, ...props }, ref) => {
        const { t } = useTranslation();
        const capsLock = useCapsLock();
        const [focus, setFocus] = React.useState(false);
        const [showPassword, setShowPassword] = React.useState(false);

        const handleFocus = () => {
            setFocus(true);
        };

        return (
            <>
                <div
                    className={clsx(
                        'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors',
                        focus && 'outline-none ring-ring border-primary2',
                        props.disabled && 'cursor-not-allowed opacity-50',
                    )}>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        className={clsx(
                            'flex-1 flex items-center h-full w-full bg-transparent text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-regular placeholder:text-slate-400 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
                            className,
                        )}
                        ref={ref}
                        {...props}
                        onFocus={handleFocus}
                        onBlur={() => setFocus(false)}
                    />
                    <button
                        type="button"
                        className="h-full w-9 flex items-center justify-center text-slate-400 hover:text-slate-500"
                        onClick={() => setShowPassword((prev) => !prev)}>
                        {showPassword ? (
                            <GoEye className="size-4 text-slate-800" />
                        ) : (
                            <GoEyeClosed className="size-4 text-slate-800" />
                        )}
                    </button>
                </div>
                <span className="text-xs text-red-500">{error}</span>
                {focus && capsLock && (
                    <div className="flex items-center content-center gap-1 mt-2">
                        <RiErrorWarningLine size={13} />
                        <span className="text-xs">{t('users.capsLockOn')}</span>
                    </div>
                )}
            </>
        );
    },
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
