import React from 'react';
import styles from './modal.module.css';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { clsx } from '@/lib/utils.ts';
import { Override } from '@/utils/type.utils.ts';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@/components/ui/button/icon-button.tsx';
import { appIcons } from '@/components/ui/app-icon/app-icon.tsx';

/**
 * Properties used by the {@link Modal}
 */
export interface ModalProps {
    /** True if loading is happening */
    isLoading?: boolean;
    showCloseIcon?: boolean;
}

/**
 * A component that opens a modal window and displays the children within
 */
export const Modal: React.FC<Override<DialogProps, ModalProps>> = ({
    // todo: add loading indicator
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading,
    children,
    onClose,
    className,
    showCloseIcon = true,
    ...props
}) => {
    const [t] = useTranslation();
    return (
        <Dialog
            onClose={onClose}
            {...props}
            PaperProps={{ className: clsx(styles.modal, className) }}>
            {showCloseIcon && (
                <IconButton
                    className={'right-6 top-6 absolute'}
                    iconKey={appIcons.close}
                    onClick={() => onClose?.({}, 'backdropClick')}
                    tooltipText={t('general.close')}
                    tooltipPlacement={'top'}
                />
            )}
            <div className={'flex-1 h-full flex flex-col'}>{children}</div>
        </Dialog>
    );
};
