import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IHistory {
    houseManagers: string;
    particularHouseManager: string;
    buildings: string;
    particularBuilding: string;
    meters: string;
    particularMeter: string;
    readings: string;
    contractors: string;
    particularContractor: string;
    prticularContractor: string;
    users: string;
    particularUser: string;
    notRoute: string;
}

interface IInitialState {
    authenticatedUser: IAUthUser;
    roleIndentified: boolean;
}

const initialState: IInitialState = {
    authenticatedUser: {
        accessToken: '',
        refreshToken: '',
        type: '',
    },
    roleIndentified: false,
};

const authRecordSlice = createSlice({
    name: 'AUTHENTICATION',
    initialState,
    reducers: {
        authUser: (state, action: PayloadAction<IAUthUser>) => {
            state.authenticatedUser = { ...action.payload };
        },
        userLogout: (state) => {
            state.authenticatedUser = {
                accessToken: '',
                refreshToken: '',
                type: '',
            };
            state.roleIndentified = false;
        },
        setRoleStatus: (state, action: PayloadAction<boolean>) => {
            state.roleIndentified = action.payload;
        },
    },
});

export const { authUser, userLogout, setRoleStatus } = authRecordSlice.actions;
export default authRecordSlice.reducer;
