import { useAppSelector } from '@/lib/hooks';
import { Navigate, useLocation } from 'react-router-dom';

const useAuth = () => {
    const { authenticatedUser } = useAppSelector((state) => state.user);
    return authenticatedUser && authenticatedUser.accessToken;
};

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    const isAuth = useAuth();

    return isAuth ? children : <Navigate to="/auth/login" state={{ from: location }} />;
};

export default ProtectedRoute;
