import React, { ReactElement, useCallback } from 'react';
import Tooltip from '@mui/material/Tooltip';
import isNil from 'lodash.isnil';
import { Button } from '@/components/ui/button/button.tsx';
import { AppIcon } from '@/components/ui/app-icon/app-icon.tsx';
import { clsx } from '@/lib/utils';

interface IconButtonProps {
    iconKey: string;
    onClick?: () => void;
    tooltipText?: string;
    className?: string;
    tooltipPlacement?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
    iconKey,
    onClick,
    tooltipText,
    className,
    tooltipPlacement,
}) => {
    const ButtonWrapper = useCallback(
        ({ children }: { children: ReactElement }) =>
            isNil(tooltipText) ? (
                <>{children}</>
            ) : (
                // @ts-expect-error there is no type for placement
                <Tooltip title={tooltipText} placement={tooltipPlacement}>
                    {children}
                </Tooltip>
            ),
        [tooltipText, tooltipPlacement],
    );

    return (
        <ButtonWrapper>
            <Button
                className={clsx('h-8 w-8 bg-transparent text-slate-800', className)}
                onClick={onClick}
                size="icon"
                variant="ghost"
                type={'button'}>
                <AppIcon icon={iconKey} />
            </Button>
        </ButtonWrapper>
    );
};
