import { AppIcon, appIcons } from '@/components/ui/app-icon/app-icon.tsx';
import { AppRoutes } from '@/route/routes.ts';

interface MenuEntry {
    icon: JSX.Element;
    title: string;
    badge?: string;
    link: string;
    access: string[];
}

interface MenuGroup {
    entries: MenuEntry[];
}

export const sideBarEntries: MenuGroup[] = [
    {
        entries: [
            {
                title: 'houseManagers',
                icon: <AppIcon icon={appIcons.houseManager} />,
                link: '/house-managers',
                access: ['SUPER_ADMIN'],
            },
            {
                title: 'buildings',
                icon: <AppIcon icon={appIcons.home} />,
                link: '/buildings',
                access: ['USER', 'ADMIN', 'SUPER_ADMIN'],
            },
            {
                title: 'meters',
                icon: <AppIcon icon={appIcons.meter} />,
                link: '/meters',
                access: ['USER', 'ADMIN', 'SUPER_ADMIN'],
            },
            {
                title: AppRoutes.READINGS,
                icon: <AppIcon icon={appIcons.counter} />,
                link: `/${AppRoutes.READINGS}`,
                access: ['ADMIN', 'SUPER_ADMIN'],
            },
        ],
    },
    {
        entries: [
            {
                title: 'contractors',
                icon: <AppIcon icon={appIcons.energyProvider} />,
                link: '/contractors',
                access: ['ADMIN', 'SUPER_ADMIN'],
            },
        ],
    },
    {
        entries: [
            {
                title: 'users',
                icon: <AppIcon icon={appIcons.users} />,
                link: '/users',
                access: ['ADMIN', 'SUPER_ADMIN'],
            },
        ],
    },
];
