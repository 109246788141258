import { configureStore, Middleware } from '@reduxjs/toolkit';
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk, ThunkMiddleware } from 'redux-thunk';
import { api } from '../baseConfig';
import authenticatedUserSlice from '../slices/auth';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedAuthenticatedUser = persistReducer(persistConfig, authenticatedUserSlice);

export const store = configureStore({
    reducer: {
        user: persistedAuthenticatedUser,
        // Add more slices here
        // keep [api.reducerPath]: api.reducer at the bottom
        [api.reducerPath]: api.reducer,
    },
    // @ts-expect-error somehow needs to be cast
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE],
            } as any,
        }).concat(thunk as ThunkMiddleware, api.middleware as Middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);
