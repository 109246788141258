import * as AvatarPrimitive from '@radix-ui/react-avatar';
import * as React from 'react';

import { clsx } from '@/lib/utils';
import { isFunction } from 'radash';
import { ReactNode } from 'react';

interface AvatarProps {
    imageSource?: string;
    getFallback?: () => string | ReactNode;
    onClick?: () => void;
    className?: string;
    backgroundType?: 'primary' | 'secondary';
    style?: React.CSSProperties;
}

export const Avatar: React.FC<AvatarProps> = ({
    imageSource,
    getFallback,
    onClick,
    className,
    backgroundType = 'primary',
    style,
}) => {
    return (
        <AvatarPrimitive.Root
            className={clsx(
                'relative flex h-8 text-xs font-semibold w-8 shrink-0 overflow-hidden rounded-full',
                isFunction(onClick) && clsx('cursor-pointer', 'hover:z-10'),
                'h-10',
                'w-10',
                className,
            )}
            style={style}>
            {imageSource ? (
                <AvatarImage src={imageSource} />
            ) : (
                <AvatarFallback
                    className={clsx(
                        backgroundType === 'primary' ? 'bg-primary2/20' : '',
                        'text-primary2',
                    )}>
                    {getFallback?.()}
                </AvatarFallback>
            )}
        </AvatarPrimitive.Root>
    );
};

const AvatarImage = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Image
        ref={ref}
        className={clsx('aspect-square h-full w-full', className)}
        {...props}
    />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={clsx(
            'flex h-full w-full items-center justify-center rounded-full bg-slate-200 dark:bg-slate-700',
            className,
        )}
        {...props}
    />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

export { AvatarFallback, AvatarImage };
