import { useAppSelector } from '@/lib/hooks';
import { Navigate, useLocation } from 'react-router-dom';
import React, { PropsWithChildren } from 'react';

const useAuth = () => {
    const { authenticatedUser } = useAppSelector((state) => state.user);
    return authenticatedUser && authenticatedUser.accessToken;
};

const RedirectChecker: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation();
    const isAuth = useAuth();
    return isAuth ? <Navigate to="/buildings" state={{ from: location }} /> : children;
};

export default RedirectChecker;
