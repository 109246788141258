import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import * as yup from 'yup';
import H3 from '@/components/typo/H3.tsx';
import { AuthFormCard } from '@/pages/auth/components/auth-form-card/auth-form-card.tsx';
import { FormInput } from '@/components/FormInputs.tsx';
import { useAuthUserMutation } from '@/redux/queries/auth.ts';
import { Button } from '@/components/ui/button/button.tsx';
import Paragraph from '@/components/typo/Paragraph.tsx';
import { authUser } from '@/redux/slices/auth.ts';
import { toast } from '@/components/ui/toast/use-toast.ts';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes } from '@/pages/auth/router.config.tsx';
import { AppLink } from '@/components/ui/app-link/app-link.tsx';

const createLoginScheme = (t: TFunction<'translation', undefined>) =>
    yup.object().shape({
        user: yup.string().required(t('validation.required')),
        password: yup.string().required(t('validation.required')),
    });

export const Login: React.FC = () => {
    const navigate = useNavigate();
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const loginSchema = useMemo(() => createLoginScheme(t), [t]);
    const [authUserRequest, { isLoading }] = useAuthUserMutation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(loginSchema),
    });

    const submit = async (credentials: ICredentials) => {
        const password = credentials.password ?? '';
        const encodedPassword = btoa(password);

        const updatedCredentials = {
            ...credentials,
            password: encodedPassword,
        };

        const res = await authUserRequest(updatedCredentials);

        if ('data' in res && res.data && 'accessToken' in res.data) {
            dispatch(authUser(res.data));
            return;
        }

        if ((res as IErrorResponse).error?.status === 401) {
            toast({
                variant: 'error',
                title: t('error.badRequest'),
                description: t('login.error.invalidCredentials'),
            });
        } else if ((res as IErrorResponse).error?.status === 'FETCH_ERROR') {
            toast({
                variant: 'error',
                title: t('error.offline'),
                description: t('error.retryLater'),
            });
        } else {
            toast({
                variant: 'error',
                title: t('error.badRequest'),
                description: t('error.retry'),
            });
        }
    };

    return (
        <AuthFormCard onSubmit={handleSubmit(submit)}>
            <div className={'w-full'}>
                <H3 className="animate-in animate-bounce md:visible">{t('login.title')}</H3>
            </div>

            <div className="grid w-full items-center gap-1.5">
                <FormInput
                    {...register('user')}
                    label={t('login.username')}
                    type="text"
                    id="username"
                    placeholder={t('login.username.placeholder')}
                    autoComplete={'username'}
                    error={errors.user?.message}
                    disabled={isLoading}
                />
            </div>

            <div className="grid w-full items-center gap-1.5 flex-grow">
                <FormInput
                    {...register('password')}
                    label={t('login.password')}
                    type="password"
                    id="password"
                    autoComplete={'current-password'}
                    placeholder={t('login.password.placeholder')}
                    error={errors.password?.message}
                    disabled={isLoading}
                />
            </div>

            <Button
                className="w-full max-w-sm text-white"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}>
                {t('login.title')}
            </Button>

            <Paragraph className="text-[13px] text-muted-foreground flex gap-2 flex-wrap">
                <span>{t('login.forgot')}</span>
                <AppLink
                    text={t('login.reset')}
                    onClick={() => navigate(`/${AuthRoutes.AUTH}/${AuthRoutes.PASSWORD_RESET}`)}
                />
            </Paragraph>
        </AuthFormCard>
    );
};
