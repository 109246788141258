import Lottie from 'lottie-react';
import React from 'react';
import animation from './animation.json';
import styles from './loading-screen.module.css';

export const LoadingScreen: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <Lottie animationData={animation} className={styles.loadingIcon} />
        </div>
    );
};
