import store from 'store2';
import englishI18n from '../../assets/i18n/en.json';
import germanI18n from '../../assets/i18n/de.json';

import 'dayjs/locale/de';
import 'dayjs/locale/en';

/**
 * The localstorage key for the apps language
 */
export const LANGUAGE_STORAGE_KEY = 'appLanguage';

/**
 * Singleton service that provides language related functionality
 */
class LanguageService {
    /**
     * Returns the current language while prioritizing the language stored in localstorage
     */
    public getCurrentLocale(): string {
        if (!store.has(LANGUAGE_STORAGE_KEY)) {
            store.set(LANGUAGE_STORAGE_KEY, this.convertToBaseLocale(navigator.language));
        }

        return store.get(LANGUAGE_STORAGE_KEY) as string;
    }
    /**
     * Persists the provided language key in the localstorage
     * @param newVal    The new value
     */
    public setLanguage(newVal: string): string {
        const base = this.convertToBaseLocale(newVal);
        store.set(LANGUAGE_STORAGE_KEY, base);
        return base;
    }

    /**
     * Gets an object of the supported languages and their translations
     */
    public getSupportedLanguages() {
        return {
            en: { translation: englishI18n },
            de: { translation: germanI18n },
        };
    }

    /**
     * Converts a locale string to its base locale (e.g. de-DE to de)
     * @param locale    The locale string to convert
     * @private
     */
    private convertToBaseLocale(locale: string) {
        if (locale.includes('-')) {
            return locale.slice(0, locale.indexOf('-'));
        }
        return locale;
    }
}

/**
 * The singleton instance of the language service
 */
export const languageService = new LanguageService();
