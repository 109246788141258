import { useEffect } from 'react';

/**
 * Hook that executes the given effect when the component is mounted
 * @param effect The effect that shall be executed
 */
export const useMount = (effect: () => void) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
};
