import { useState, useEffect, useCallback } from 'react';

const currentVersion = import.meta.env.VITE_APP_VERSION;

export const useAppVersion = (checkInterval = 60000) => {
    const [newVersionAvailable, setNewVersionAvailable] = useState(false);

    const checkVersion = useCallback(async () => {
        try {
            const response = await fetch('/version.json');
            if (!response.ok) return false;
            const data = await response.json();

            if (data.version !== (currentVersion ?? '').split('-')[0]) {
                setNewVersionAvailable(true);
                return true; // Version has changed
            }
            return false; // Version is the same
        } catch (error) {
            console.error('Error checking version:', error);
            return false;
        }
    }, []);

    useEffect(() => {
        void checkVersion();
        const interval = setInterval(checkVersion, checkInterval);

        return () => clearInterval(interval);
    }, [checkVersion, checkInterval]);

    return {
        newVersionAvailable,
        checkVersion,
    };
};
