import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { languageService } from './services/language/language.service';

void i18next.use(initReactI18next).init({
    resources: languageService.getSupportedLanguages(),
    lng: languageService.getCurrentLocale(),
    interpolation: {
        escapeValue: false, // react already takes care of escaping
    },
});

export default i18next;
