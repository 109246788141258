import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authUser, userLogout } from '@/redux/slices/auth.ts';
import { useAppSelector } from '@/lib/hooks';

const AuthInitializer: React.FC = () => {
    const dispatch = useDispatch();
    const {
        authenticatedUser: { accessToken, refreshToken, type },
    } = useAppSelector((state) => state.user);

    useEffect(() => {
        if (accessToken && refreshToken && type) {
            dispatch(authUser({ accessToken, refreshToken, type: type }));
        } else {
            dispatch(userLogout());
        }
    }, [accessToken, dispatch, refreshToken, type]);

    return null;
};

export default AuthInitializer;
