import { Avatar } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button/button.tsx';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown/dropdown-menu.tsx';
import { useAppDispatch } from '@/lib/hooks';
import { clsx } from '@/lib/utils';
import { api } from '@/redux/baseConfig';
import { useGetCurrentUserInfoQuery } from '@/redux/queries/users';
import { userLogout } from '@/redux/slices/auth';
import { RiMenuUnfoldFill } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { UserUtils } from '@/utils/user.utils.ts';
import { useState } from 'react';
import ChangePassword from '@/components/ChangePassword';
import Tooltip from '@mui/material/Tooltip';

interface INavbar {
    toggle: (
        e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLLabelElement>,
        mode?: boolean,
    ) => void;
    collaps: boolean;
}

const Navbar = ({ toggle, collaps }: INavbar) => {
    const dispatch = useAppDispatch();
    const [t] = useTranslation();
    const { data: currentUserInfo } = useGetCurrentUserInfoQuery();
    const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

    return (
        <>
            <header className="bg-background relative  border-l top-0 overflow-hidden w-full flex items-center justify-between px-4 h-16 z-10">
                <Tooltip
                    title={t(`general.${collaps ? 'openNav' : 'closeNav'}`)}
                    placement={'right'}>
                    <Button
                        onClick={(e) => toggle(e)}
                        size="icon"
                        variant="ghost"
                        className="absolute hidden md:inline-flex left-3 hover:bg-transparent  hover:text-primary2 text-primary">
                        <RiMenuUnfoldFill
                            className={clsx('text-xl rotate-180', collaps && 'rotate-0')}
                        />
                    </Button>
                </Tooltip>
                <div className="flex items-center gap-3">
                    <label
                        htmlFor="menu-open"
                        className="shadow-lg rounded-full p-2 bg-gray-100 text-gray-600 md:hidden"
                        data-dev-hint="floating action button"
                        onClick={(e) => toggle(e, true)}>
                        <svg
                            className="h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    </label>
                </div>
                <div className="flex items-center justify-center gap-4 h-full">
                    {currentUserInfo?.houseManagementData.wwuNumber && (
                        <>
                            <Badge variant={'slate'}>
                                {currentUserInfo?.houseManagementData.wwuNumber}
                            </Badge>
                            <div className={'bg-border h-9 w-px '} />
                        </>
                    )}
                    <div>
                        <DropdownMenu>
                            <DropdownMenuTrigger className="active:outline-0 focus:outline-0 after:outkine-0">
                                <span className="flex items-center gap-3">
                                    <Avatar
                                        getFallback={() =>
                                            (currentUserInfo &&
                                                UserUtils.getUserInitials(currentUserInfo)) ??
                                            ''
                                        }
                                    />
                                    <div className="hidden md:flex flex-col items-start font-semibold hover:text-primary2">
                                        <span className="text-sm">
                                            {currentUserInfo?.firstName} {currentUserInfo?.lastName}
                                        </span>
                                        <span className="text-xs mt-[-1px] text-slate-500 font-normal">
                                            {currentUserInfo?.houseManagementData.name}
                                        </span>
                                    </div>
                                </span>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="start">
                                <DropdownMenuItem onClick={() => setShowPasswordChangeModal(true)}>
                                    {t('user.actions.changePassword')}
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                    onClick={() => {
                                        dispatch(userLogout());
                                        dispatch(api.util.resetApiState());
                                    }}>
                                    {t('user.actions.logout')}
                                </DropdownMenuItem>
                            </DropdownMenuContent>
                        </DropdownMenu>
                        <div />
                    </div>
                </div>
            </header>
            <ChangePassword
                open={showPasswordChangeModal}
                onClose={() => setShowPasswordChangeModal(false)}
            />
        </>
    );
};

export default Navbar;
